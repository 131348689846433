import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
//import data from './example.json';
import * as C from '../services/conf.json';


@Injectable({
  providedIn: 'root'
})
export class VendorsService {
  //public urlApi = 'http://localhost:8080/index.php/'; //local
  public urlApi = Object(C).urlApi;
  //public urlApi = 'https://tbi-purchasing-api-dev.intishaka.com/frontend/web/index.php/'; //local
  constructor(
      private http: HttpClient,
      private authservice : AuthService,
     // private conf : Conf
  ) { 
   // console.log(Object(C).urlApi);
   // console.log(authservice.getIntenalToken());

  }

  _httpOptionsparams(params:any){
    return {
        headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        //'Authorization' : 'Bearer '+ this.authservice.getToken()
        'Authorization' : 'Bearer ' + this.authservice.getIntenalToken()
        })
    }; 
  }

   /* Vendor */
   GetVendorType(qs:any){
    
    return this.http.get<any>(this.urlApi + 'vendor/vendortypes?'+qs,  this._httpOptionsparams({}));
  }

  /* View Vendor */
  GetViewVendorType(id:any){
    
    return this.http.get<any>(this.urlApi + 'vendor/vendortypes/view/'+id,  this._httpOptionsparams({}));
  }
  /* Post Update Vendor Type */
  PostUpdateVendorType(id:any,params:any){
    return this.http.post<any>(this.urlApi + 'vendor/vendortypes/update/'+id, (params), this._httpOptionsparams({}));
  }

  /* Vendor */
  GetVendor(qs:any){
    return this.http.get<any>(this.urlApi + 'vendor/vendors?'+qs,  this._httpOptionsparams({}));
  }
  /* Vendor */
  GetVendorquery(qs:any){
    return this.http.get<any>(this.urlApi + 'vendor/vendors/query?'+qs,  this._httpOptionsparams({}));
  }

  /* View Vendor */
  GetViewVendor(id:any){
    return this.http.get<any>(this.urlApi + 'vendor/vendors/view/'+id,  this._httpOptionsparams({}));
  } 
   /* Post New Vendor */
   PostNewVendor(params:any){
    return this.http.post<any>(this.urlApi + 'vendor/vendors/create', (params), this._httpOptionsparams({}));
  }

  PostUpdateVendor(id:any,params:any){
    return this.http.post<any>(this.urlApi + 'vendor/vendors/update/'+id, (params), this._httpOptionsparams({}));
  }
  /* Post New Vendor  Type*/
  PostNewVendorType(params:any){
    return this.http.post<any>(this.urlApi + 'vendor/vendortypes/create', (params), this._httpOptionsparams({}));
  }

  // /* Post Update Product */
  // PostUpdateProduct(params:any){
  //   return this.http.post<any>(this.urlApi + 'p/product/update', (params), this._httpOptionsparams({}));
  // }
}
