import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import * as $ from "jquery";
import { MasterService } from 'src/app/services/master.service';
import { filter, interval, switchMap } from 'rxjs';
import { formatDistanceToNow } from 'date-fns';
import { ErrorService } from 'src/app/services/error.service';


@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.css']
})
export class PrivateComponent {
  
  public menuNormal;
  _child:any;
  currentTime:any;
  title:string = "Procurement Purchasing";
  notifs : any;
  Rightcol: string = screen.height + 'px';
  breadcrumbs:any = [{title:'Home'},{title:'Dashboard'}];
  public pageInfo : any;
  public user : any;
  public user_access : any;
  alert:any = {show:false, message:''};
  errorMessage: string | null = null;
  constructor(private route: Router,
    private auth :AuthService,
    private m : MasterService,
    private cd: ChangeDetectorRef,
    private errorService: ErrorService,
    
    ) {
      this.user = auth.getDecodedToken();
      this.user_access = this.user.user_access;
      if ( auth.getIntenalToken() == undefined){
        this.route.navigate(['/signin']);
      }

      setInterval(() => {
        this.currentTime = new Date();
      }, 1000);
  }

  setPageInfo(params:any){
    this.pageInfo = params;
    this.cd.detectChanges();
  }

  setBreadcrumb(params:any){
    this.breadcrumbs = params;
    this.cd.detectChanges();
  }

  loadChild(component : any) {
    this._child = component;
  }

  ngOnInit() {
    this.errorService.error$.subscribe(message => {
     // this.errorMessage = message;
     if (message != null){
        this.alert = {show:true, message:message};
     }else{
      this.alert = {show:false, message:''};
     }
      console.log('open error',message);
    });

    // Hapus error saat navigasi berubah
    this.route.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      console.log('url',event.urlAfterRedirects);
      if (this.errorService.shouldClearError(event.urlAfterRedirects)) {
        this.errorService.clearError();
      }
    });

    this.loadNotif();
    this.startPolling();
  }

  toggleClicked1(event: MouseEvent) {
    var body = $("#navmenu");
    var menu = $("#sidebar-menu");
    
    // toggle small or large menu
    if (body.hasClass("nav-md")) {
      menu.find("li.active ul").hide();
      menu
        .find("li.active")
        .addClass("active-sm")
        .removeClass("active");
    } else {
      menu.find("li.active-sm ul").show();
      menu
        .find("li.active-sm")
        .addClass("active")
        .removeClass("active-sm");
    }
    body.toggleClass("nav-md nav-sm");
  }


  backtoportal(){
    //this.auth.logout();
    if ( this.user.ui.home != undefined ){

      window.location.href = this.user.ui.home;
    }
    return;
    //console.log(this.user.ui.logoff);
  }

  changePassword(){
    this.auth.logout();
    if ( this.user.ui.changepassword != undefined ){
      window.location.href = this.user.ui.changepassword;
    }
    return;
    //console.log(this.user.ui.logoff);
  }


  logout(){
    this.auth.logout();
    if ( this.user.ui.logoff != undefined ){
      window.location.href = this.user.ui.logoff;
    }
    return;
    //console.log(this.user.ui.logoff);
  }
  
  loadNotif(){
    this.m.GetNotif('').subscribe((response: any) => { 
      this.notifs = response;
      console.log(this.notifs);
      
    })
  }
  ngDoCheck() {
    // console.log('check');
    //this.loadNotif();
    
  }

  startPolling(intervalMs: number = 20000) {
    interval(intervalMs)
      .pipe(switchMap(() => this.m.GetNotif('')))
      .subscribe({
        next: (response) => { 
          this.notifs = response;
          // console.log(this.notifs);
          
        },
        error: (err) => console.error('Error fetching notifications:', err),
      });
  }

  timeAgo(date:any){
    return formatDistanceToNow(new Date(date), { addSuffix: true })
  }

  readNotif(item:any){
    // console.log(item.link);
    this.m.ReadNotif(item.id).subscribe((response: any) => { 
      
    //   //console.log(this.notifs);
      this.loadNotif();
      this.route.navigate([item.link]);
      
    })
  }

  clearError() {
    //this.alert = false;
    this.alert.show = false;
    this.errorService.clearError(); // Hapus error setelah klik OK atau close modal
  }

}
