<!-- sidebar menu -->
<div #sidebarmenu id="sidebar-menu" class="main_menu_side hidden-print main_menu">
    <div class="menu_section">
      <ul class="nav side-menu">
        <li routerLinkActive="active"><a (click)="backtoportal()">
          <i class="fa fa-home"></i>
          Portal</a>
        </li>
        @for (item of menuItems; track item) {
            @if(item.children) {
              <li routerLinkActive="active" [ngClass]="{'active':isopenmenu[item.title]}">
                <a (click)="openmenu(item.title);anchorClicked($event,item.title)" routerLinkActive="active" [ngClass]="{'active':isopenmenu[item.title]}">
                  <i class="fa" [ngClass]="item.icon"></i> {{ item.title }}
                  <span class="fa fa-chevron-down"></span>
                </a>
                <ul class="nav child_menu" >
                  <li *ngFor="let child of item.children">
                    <a [routerLink]="child.link" routerLinkActive="current-page">{{ child.title }}</a>
                  </li>
                </ul>
            </li>
            }@else {
              <li routerLinkActive="active">
                <a [routerLink]="item.link" routerLinkActive="active">
                  <i class="fa" [ngClass]="item.icon"></i> {{ item.title }}
                </a>
              </li>
            }
          
        }
      </ul>

    </div>
   
  </div>
  <!-- /sidebar menu -->