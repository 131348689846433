// error.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root' // Bisa diakses di semua komponen
})
export class ErrorService {
  private errorMessage = new BehaviorSubject<string | null>(null);
  error$ = this.errorMessage.asObservable(); // Observable untuk dipantau Parent A
  private currentUrl: string | null = null; // Menyimpan URL saat error terjadi


  setError(message: string, url: string) {
    this.errorMessage.next(message); // Simpan error
    this.currentUrl = url; // Simpan lokasi error
  }

  clearError() {
    this.errorMessage.next(null); // Hapus error
    this.currentUrl = null; // Reset URL saat error dihapus
  }

  shouldClearError(newUrl: string): boolean {
    return this.currentUrl !== null && this.currentUrl !== newUrl;
  }
}
