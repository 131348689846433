import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageComponent } from './layouts/sample/page/page.component';
import { PrivateComponent } from './layouts/private/private/private.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { CustomNavComponent } from './components/custom-nav/custom-nav.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, NgFor, registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id'; 
import { NgSelectModule } from '@ng-select/ng-select';
import { NgChartsModule } from 'ng2-charts';
import { UrlmanagementComponent } from './pages/private/urlmanagement/urlmanagement.component';
import { authGuard } from './auth.guard';



registerLocaleData(localeId, 'id'); 

@NgModule({
  declarations: [
    AppComponent,
    PageComponent,
    PrivateComponent,
    MainPageComponent,
    CustomNavComponent,
    UrlmanagementComponent
    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
   
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgFor,
    NgSelectModule,
    NgChartsModule,
    NgbTooltipModule,
    CommonModule
  ],
  providers: [authGuard, {provide: LOCALE_ID, useValue: "en-US"}],
  bootstrap: [AppComponent]
})
export class AppModule { }
