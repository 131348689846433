import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import * as C from '../services/conf.json';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class MasterService {
  //public urlApi = 'http://localhost:8080/index.php/'; //local
  //public urlApi = 'https://tbi-purchasing-api-dev.intishaka.com/frontend/web/index.php/'; //local
  public urlApi = Object(C).urlApi;
  
  constructor(
      private http: HttpClient,
      private authservice : AuthService,
      private route: Router
  ) { 
    // if ( authservice.getIntenalToken() == undefined){
    //   this.route.navigate(['/signin']);
    // }

  }

  _httpOptionsparams(params:any){
    return {
        headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        //'Authorization' : 'Bearer '+ this.authservice.getToken()
        'Authorization' : 'Bearer ' + this.authservice.getIntenalToken()
        })
    }; 
  }

  /* Department */
  GetDepartment(params:any){
    return this.http.get<any>(this.urlApi + 'master/departments/all',  this._httpOptionsparams({}));
  }

   /* Branch */
  GetBranch(params:any){
    return this.http.get<any>(this.urlApi + 'master/branches/all',  this._httpOptionsparams({}));
  }


  /* Office Location */
  GetOfficeLocation(params:any){
    return this.http.get<any>(this.urlApi + 'master/officelocations',  this._httpOptionsparams({}));
  }

  /* kategori asset */
  GetKategoriasset(params:any){
    return this.http.get<any>(this.urlApi + 'master/mkategoriassets',  this._httpOptionsparams({}));
  }
  /* Jenis asset */
  GetJenisasset(params:any){
    return this.http.get<any>(this.urlApi + 'master/mjenisassets?'+params,  this._httpOptionsparams({}));
  }

  /* Item barang asset */
  GetItembarang(params:any){
    return this.http.get<any>(this.urlApi + 'master/mitembarangs?'+params,  this._httpOptionsparams({}));
  }

  /* Keterangan */
  GetKeterangan(params:any){
    return this.http.get<any>(this.urlApi + 'master/mketerangans',  this._httpOptionsparams({}));
  }

  /* Priority asset */
  GetPriority(params:any){
    return this.http.get<any>(this.urlApi + 'master/mpriorities',  this._httpOptionsparams({}));
  }

  /* Sbu */
  GetSbu(params:any){
    return this.http.get<any>(this.urlApi + 'master/msbus',  this._httpOptionsparams({}));
  }

   /* Currency */
   GetCurrency(params:any){
    return this.http.get<any>(this.urlApi + 'master/currencies',  this._httpOptionsparams({}));
  }

  /* Currency */
  GetMetodePembayaran(params:any){
    return this.http.get<any>(this.urlApi + 'master/metodepembayarans',  this._httpOptionsparams({}));
  }

  
  /* mtindaklanjut */
  GetMtindaklanjut(){
    return this.http.get<any>(this.urlApi + 'master/mtindaklanjuts',  this._httpOptionsparams({}));
  }
  
  /* City */
  GetCity(qs:any){
    return this.http.get<any>(this.urlApi + 'master/cities?'+qs,  this._httpOptionsparams({}));
  }

  /* City */
  GetPostalcode(qs:any){
    return this.http.get<any>(this.urlApi + 'master/postalcodes?'+qs,  this._httpOptionsparams({}));
  }

  /* Gender */
  GetGender(qs:any){
    return this.http.get<any>(this.urlApi + 'master/genders?'+qs,  this._httpOptionsparams({}));
  }

  /* tax */
  GetTax(qs:any){
    return this.http.get<any>(this.urlApi + 'master/taxes?'+qs,  this._httpOptionsparams({}));
  }

   /* top */
   GetTop(qs:any){
    return this.http.get<any>(this.urlApi + 'master/tops?'+qs,  this._httpOptionsparams({}));
  }
  /* vendor info */
  GetVendorInfo(qs:any){
    return this.http.get<any>(this.urlApi + 'master/vendorinfos?'+qs,  this._httpOptionsparams({}));
  }  


  /* role info */
  GetRoles(qs:any){
    return this.http.get<any>(this.urlApi + 'master/authitems?'+qs,  this._httpOptionsparams({}));
  }
  
  /* Permission Group info */
  GetPermissionGroup(qs:any){
    return this.http.get<any>(this.urlApi + 'master/authpermissiongroups?'+qs,  this._httpOptionsparams({}));
  }

  /* role group info */
  GetRoleGroup(qs:any){
    return this.http.get<any>(this.urlApi + 'master/authitemgroups?'+qs,  this._httpOptionsparams({}));
  }


  /* role item group info */
  GetViewAuthItem(name:any){
    return this.http.get<any>(this.urlApi + 'master/authitems/view/'+name,  this._httpOptionsparams({}));
  }
  
   /* role item update info */
   PostUpdateAuthItem(name:any, params:any){
    //console.log(params);
    return this.http.post<any>(this.urlApi + 'master/authitems/update/'+name, (params), this._httpOptionsparams({}));
  }

   /* role item update info */
   PostAddAuthItem( params:any){
    //console.log(params);
    return this.http.post<any>(this.urlApi + 'master/authitems/create', (params), this._httpOptionsparams({}));
  }

  /* route list */
  GetRouteList(qs:any){
    return this.http.get<any>(this.urlApi + 'master/routelists?'+qs,  this._httpOptionsparams({}));
  }


  /* role item update info */
  GetReportPR( params:any){
    //console.log(params);
    return this.http.post<any>(this.urlApi + 'report/reports', (params), this._httpOptionsparams({}));
  }
  
  //master/authitems/view/PurchasingStaff

  /* report dashboard */
  /* vendor */
  GetSumVendor( params:any){
    //console.log(params);
    return this.http.post<any>(this.urlApi + 'report/summaries/vendor', (params), this._httpOptionsparams({}));
  }
  /* pr */
  GetSumPurchaserequest( params:any){
    //console.log(params);
    return this.http.post<any>(this.urlApi + 'report/summaries/purchaserequest', (params), this._httpOptionsparams({}));
  }

  /* cqr */
  GetSumCqr( params:any){
    //console.log(params);
    return this.http.post<any>(this.urlApi + 'report/summaries/competitivequotationrecord', (params), this._httpOptionsparams({}));
  }
  
  /* PO */
  GetSumPO( params:any){
    //console.log(params);
    return this.http.post<any>(this.urlApi + 'report/summaries/purchaseorder', (params), this._httpOptionsparams({}));
  }

  /* PR Dept */
  GetPRDept( params:any){
    //console.log(params);
    return this.http.post<any>(this.urlApi + 'report/summaries/prdept', (params), this._httpOptionsparams({}));
  }

  /* PR Dept */
  GetPRBranch( params:any){
    //console.log(params);
    return this.http.post<any>(this.urlApi + 'report/summaries/prbranch', (params), this._httpOptionsparams({}));
  }

  /* GET Notif */
  GetNotif( params:any){
    //console.log(params);
    return this.http.get<any>(this.urlApi + 'master/notifs',  this._httpOptionsparams({}));
  }
  /* GET Notif */
  ReadNotif( id:any){
    //console.log(params);
    return this.http.get<any>(this.urlApi + 'master/notifs/view/'+id,  this._httpOptionsparams({}));
  }

  /* GET Matrix Approval */
  GetMApproval(modulecode:any, item:any){
    //console.log(params);
    return this.http.get<any>(this.urlApi + 'master/mapprovals?mc='+modulecode+'&id_trans='+item.id+'&employee_id='+item.requestor_employee_id,  this._httpOptionsparams({}));
  }

  GetAccessquery(params:any){
    return this.http.post<any>(this.urlApi + 'master/mapprovals/access',(params), this._httpOptionsparams({}));
  }

  GetMenu(params:any){
    return this.http.get<any>(this.urlApi + 'master/routelists/menu'+params,  this._httpOptionsparams({}));
  }

/* POST Route */
  PostAddRoute( params:any){
    //console.log(params);
    return this.http.post<any>(this.urlApi + 'master/routelists/create', (params), this._httpOptionsparams({}));
  }

  PostEditRoute(id:any, params:any){
    //console.log(params);
    return this.http.post<any>(this.urlApi + 'master/routelists/update/'+id, (params), this._httpOptionsparams({}));
  }

  /* GET Route */
  GetParentRoute( params:any){
    return this.http.get<any>(this.urlApi + 'master/routelists/parent'+params,  this._httpOptionsparams({}));
  }

  GetDetailRoute( params:any){
    return this.http.get<any>(this.urlApi + 'master/routelists/detail/'+params,  this._httpOptionsparams({}));
  }

}

