import { Component } from '@angular/core';
import {  Router } from '@angular/router';
import * as $ from "jquery";
import { AuthService } from 'src/app/services/auth.service';
import { MasterService } from 'src/app/services/master.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-custom-nav',
  templateUrl: './custom-nav.component.html',
  styleUrls: ['./custom-nav.component.css']
})
export class CustomNavComponent {
  menuItems: any = [];
  isopenmenu:any= [];
  user:any;
  constructor(private route: Router,
    private auth :AuthService,
    private m : MasterService,
    private menuService: MenuService
    ) {
    //console.log(this.route.url);
    this.user = auth.getDecodedToken();
  }
  ngOnInit() {
    // this.loadMenu();
    this.loadMenuService();
   // console.log(this.route.url); // ActivatedRoute
  }

  openmenu(menu:any){
    if (!this.isopenmenu[menu])
      this.isopenmenu[menu] = true;
    else{
      this.isopenmenu[menu] = false;
    }
    console.log(this.isopenmenu);
  }

  anchorClicked(event: MouseEvent, id :any) {
    console.log('clicked');
    var body = $("#navmenu");
    console.log(body.hasClass("nav-md"));
    let $li = $("#" + id).parent();

    if ($li.is(".active")) {
      console.log($("ul:first", $li).hasClass("open"));
      if ( $("ul:first", $li).hasClass("open") ){
        $li.removeClass("active active-sm");
        $("ul:first", $li).removeClass("open");
        $("ul:first", $li).slideUp(function() {});
      }else{
        //$li.addClass("active");
        $("ul:first", $li).addClass("open");
        $("ul:first", $li).slideDown(function() {});
      }
      
    } else {
      // prevent closing menu if we are on child menu
      if (!$li.parent().is(".child_menu")) {
        $("#sidebar-menu")
          .find("li")
          .removeClass("active active-sm");
        $("#sidebar-menu")
          .find("li ul")
          .slideUp();

          $("#sidebar-menu")
          .find("li ul").removeClass("open");
      }

      $li.addClass("active");
      $("ul:first", $li).addClass("open");
      $("ul:first", $li).slideDown(function() {});
     
    }
  }

  clear(){
    this.isopenmenu['master'] = false
    let $sm = $("#sidebar-menu").parent();
    $sm.find("li ul").slideUp(function() {});
    $sm.find("li ul").removeClass("open");
    console.log(screen.height);
  }

  backtoportal(){
    //this.auth.logout();
    if ( this.user.ui.home != undefined ){

      window.location.href = this.user.ui.home;
    }
    return;
    //console.log(this.user.ui.logoff);
  }

  loadMenu(){
    this.m.GetMenu('').subscribe((data: any) => { 
      this.menuItems = data;
    })
  }

  loadMenuService(){
  this.menuService.getMenu('');
    this.menuService.menu$.subscribe(menu => {
      this.menuItems = menu;
    });
  }
}
