<section id="alert" >
    <div
      class="container-fluid d-flex h-100 justify-content-center align-items-center"
    >
      <div class="alert card access-card">
        <div
          class="card-body d-flex flex-column justify-content-center align-items-center text-center"
          style="height: 200px"
        >
          <div class="img-container">
            <img [src]="'/assets/icon/access-icon.svg'" alt="icon" />
          </div>
          <h2 class="card-title mt-4">404 - Page Not Found</h2>
          <p class="card-content">
            The page you are looking for does not exist or may have been moved.
          </p>
          <!-- <p>Error ID: 82374-2342384-2374272</p> -->
          <button class="btn btn-warning" (click)="goToDashboard()">Back to Home</button>
        </div>
      </div>
    </div>
  </section>