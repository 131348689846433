import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import * as C from '../services/conf.json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuSubject = new BehaviorSubject<any[]>([]);
  menu$ = this.menuSubject.asObservable();
  public apiUrl = Object(C).urlApi;
  constructor(private http: HttpClient,private authService : AuthService) { }

  private _httpOptionsparams(params: any) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Authorization': 'Bearer ' + this.authService.getIntenalToken() // Ambil token dari AuthService
      }),
      params: params // Jika ada query parameters
    };
  }

  // getMenu(params:any): void {
  //   if (this.menuSubject.getValue().length === 0) {
  //     this.http.get<any[]>(this.apiUrl+'master/routelists/menu',this._httpOptionsparams(params)).pipe(
  //       tap(menu => this.menuSubject.next(menu))
  //     ).subscribe();
  //   }
  // }

  getMenu(params: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (this.menuSubject.getValue().length === 0) {
        this.http.get<any[]>(this.apiUrl + 'master/routelists/menu', this._httpOptionsparams(params))
          .pipe(
            tap(menu => {
              this.menuSubject.next(menu);
              resolve(menu); // Pastikan menu dikembalikan setelah dimuat
            })
          ).subscribe({
            error: (err) => reject(err)
          });
      } else {
        resolve(this.menuSubject.getValue()); // Jika sudah ada, langsung return
      }
    });
  }

  

  updateMenu(): void {
    this.http.get<any[]>(this.apiUrl+'master/routelists/menu', this._httpOptionsparams({})).subscribe(menu => {
      this.menuSubject.next(menu); // Update BehaviorSubject agar sidebar ikut berubah
    });
  }

  // checkMenuAccess(menuList: any[], url: string): boolean {
  //   for (const menuItem of menuList) {
  //     if (menuItem.link  && url.startsWith(menuItem.link)) {
  //       return true; // URL ditemukan dalam menu utama
  //     }
  //     if (menuItem.children && menuItem.children.length > 0) {
  //       if (this.checkMenuAccess(menuItem.children, url)) {
  //         return true; // URL ditemukan dalam children
  //       }
  //     }
  //   }
  //   return false; // Tidak ditemukan
  // }

  checkMenuAccess(menuList: any[], url: string): boolean {
    for (const menuItem of menuList) {
      if (menuItem.link && url.startsWith(menuItem.link)) {
        return true; // Jika URL mulai dengan link di menu, izinkan akses
      }
      if (menuItem.children && menuItem.children.length > 0) {
        if (this.checkMenuAccess(menuItem.children, url)) {
          return true;
        }
      }
    }
    return false;
  }
  
}
