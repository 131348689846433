<div id="navmenu" class="nav-md">
    <div class="container body">
      <div class="main_container">
        <div class="col-md-3 left_col">
          <div class="left_col scroll-view">
            <div class="navbar nav_title" style="border: 0;">
              <a class="site_title" style="padding-left: 0px;background-color: #fff;">
                <!-- <i class="fa fa-paw"></i>  -->
                <!-- <span>{{title}}</span> -->
                <img src="assets/logo2.png" alt="..." style="height:100%;margin-top:-10px;">
              </a>
            </div>

            <div class="clearfix"></div>

            <!-- menu profile quick info --> 
            <div class="profile clearfix" style="margin-top:10px;">
              <div class="profile_pic">
                <img src="{{user.profile.photo}}" alt="..." class="img-circle profile_img">
              </div>
              <div class="profile_info">
                <span>Welcome,</span>
                <h2>{{user.profile.fullname}}</h2>
              </div>
            </div>
            <!-- /menu profile quick info -->

            <br />

            <app-custom-nav></app-custom-nav>

            <!-- /menu footer buttons -->
            <!-- <div class="sidebar-footer hidden-small">
              <a data-toggle="tooltip" data-placement="top" title="Settings">
                <span class="glyphicon glyphicon-cog" aria-hidden="true"></span>
              </a>
              <a data-toggle="tooltip" data-placement="top" title="FullScreen">
                <span class="glyphicon glyphicon-fullscreen" aria-hidden="true"></span>
              </a>
              <a data-toggle="tooltip" data-placement="top" title="Lock">
                <span class="glyphicon glyphicon-eye-close" aria-hidden="true"></span>
              </a>
              <a data-toggle="tooltip" data-placement="top" title="Logout" href="login.html">
                <span class="glyphicon glyphicon-off" aria-hidden="true"></span>
              </a>
            </div> -->
            <!-- /menu footer buttons -->
          </div>
        </div>

        <!-- top navigation -->
        <div class="top_nav">
          <div class="nav_menu">
              <div class="nav toggle">
                <a id="menu_toggle"  (click)="toggleClicked1($event)"><i class="fa fa-bars"></i></a>
              </div>
              <nav class="nav navbar-nav">
              <ul class=" navbar-right">
                <li class="nav-item dropdown open" style="padding-left: 15px;" ngbDropdown>
                  <a href="javascript:;" class="user-profile dropdown-toggle" aria-haspopup="true" id="navbarDropdown" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                    <img src="{{user.profile.photo}}" alt="">{{user.profile.fullname}}
                  </a>
                  <div ngbDropdownMenu class="dropdown-menu dropdown-usermenu pull-right" aria-labelledby="navbarDropdown">
                    <!-- @for (us of user_access; track us; let idx = $index) { 
                      <a class="dropdown-item"  >{{us.description}}</a>
                    } -->
                    <!-- <a class="dropdown-item"  (click)="backtoportal()">Portal</a> -->
                    <a class="dropdown-item"  (click)="changePassword()">Change Password <i class="fa fa-key pull-right" 
                      style="
                          margin-right: 10px;
                          right: 0px;
                          position: absolute;
                      "></i></a>
                    
                    <a class="dropdown-item"  (click)="logout()">Log Out <i class="fa fa-sign-out pull-right" style="
                      margin-right: 10px;
                      right: 0px;
                      position: absolute;
                  "></i></a>
                  </div>
                </li>

                <li role="presentation" class="nav-item dropdown open" ngbDropdown>
                  <a href="javascript:;" class="dropdown-toggle info-number" id="navbarDropdown1" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                    <!-- <i class="fa fa-regular fa-bell"></i> -->
                    <i class="fa fa-solid fa-bell"></i>
                    @if ( this.notifs ){
                      @if (this.notifs.meta.totalCount > 0){
                        <span class="badge bg-green">{{this.notifs.meta.totalCount}}</span>
                      }
                    }
                  </a>
                  @if ( this.notifs ){
                    @if (this.notifs.meta.totalCount > 0){
                      <ul ngbDropdownMenu class="dropdown-menu list-unstyled msg_list scrollable-dropdown" role="menu" aria-labelledby="navbarDropdown1">
                        @for (notif of this.notifs.data; track notif) {
                        <li class="nav-item">
                          <a class="dropdown-item" (click)="readNotif(notif)">
                            <span class="image"><img src="/assets/images/noname.png" alt="Profile Image" /></span>
                            <span>
                              <!-- <span>{{notif.fullname}}</span> -->
                              <span>Notification</span>
                              <span class="time" style="font-weight: bold;">{{notif.created_at | date: 'dd/MM/yyyy hh:mm:ss'}}</span>
                            </span>
                            <span class="message" [innerHTML]="notif.message">
                              
                            </span>
                          </a>
                        </li>
                      }
                      
                      </ul>
                    }@else {
                      <ul ngbDropdownMenu style="display: none;"></ul>
                    }
                }
                </li>
                
              </ul>
            </nav>
            
          </div>
        </div>
        <!-- /top navigation -->

    <!-- page content -->
    <div class="right_col" role="main" [style.min-height]="Rightcol" id="right_col">
      <div class="col-md-12 col-sm-12 " style="margin-top:-5px;">
        @if(alert.show){
            <div class="alert alert-danger alert-dismissible fade show" role="alert" style="margin-top:10px;margin-bottom: 5px;;">
          
              <h5><i class="bi bi-slash-circle"></i> <strong> Alert</strong></h5>
              {{alert.message}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="clearError()" >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
          }

        <div class="page-title">
          <div class="float-left">
            @if(breadcrumbs){
            <ol class="breadcrumb">
              @for (item of breadcrumbs; track item) {
                @if(item.url){
                  <li class="breadcrumb-item"><a routerLink={{item.url}}>{{item.title}}</a></li>
                }@else {
                  <li class="breadcrumb-item">{{item.title}}</li>
                }
              }
            </ol>
          }
          </div>
        <div class="float-right pt-3" id="time">
          <strong>{{ currentTime | date:'EEEE' : 'en-US' }}</strong>,{{ currentTime | date:' dd MMMM y hh:mm:ss a' : 'en-US' }}
         </div>
      </div>
      </div>
        <router-outlet ></router-outlet>
      </div>
      <!-- /page content -->

        <!-- footer content -->
        <footer>
          <div class="pull-right">
            Copyright © 2020 Intishaka Global Solusindo. All rights reserved.
          </div>
          <div class="clearfix"></div>
        </footer>
        <!-- /footer content -->
      </div>
    </div>
  </div>