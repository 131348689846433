import { Component } from '@angular/core';
import { PrivateComponent } from 'src/app/layouts/private/private/private.component';
import { AuthService } from 'src/app/services/auth.service';
import { MasterService } from 'src/app/services/master.service';
import { VendorsService } from 'src/app/services/vendors.service';

@Component({
  selector: 'app-urlmanagement',
  templateUrl: './urlmanagement.component.html',
  styleUrl: './urlmanagement.component.css'
})
export class UrlmanagementComponent {

  data : any = [];
  paging:any = {};
  totalpage:any;
  last_index:any;
  first_index:any;
 
  show_options:any = [1, 10,25,50,100];
  child_search:any = {show:25,keyword:''};
  pagination:any = {current:1,isnext:false,isprev:false,totalpages:1,totalrecords:0,first_index:0,last_index:0,next_page:0,previous_page:0};
  
  constructor(
    public vs: VendorsService,
    public m:MasterService,
    public auth :AuthService,
    private mainpage : PrivateComponent
    ) { 
          this.mainpage.setBreadcrumb([{title:'Role Template'}]);
   
  }
  ngOnInit(): void
  {
    this.callsearch();
  }
  
  load(qs:any) {
    this.m.GetRouteList(qs).subscribe((response: any) => { 
      this.data = response.data;
      this.paging = response.page;
      this.pagination.current = this.paging.self['page'];
      this.pagination.totalrecords = response.meta['totalCount'];
      if ( this.paging.next != ''){
        this.pagination.isnext = true;
        this.pagination.next_page = this.paging.next['page'];
      }else{
        this.pagination.isnext = false;
      }
      if ( this.paging.prev != ''){
        this.pagination.isprev = true;
        this.pagination.previous_page = this.paging.prev['page'];
      }else{
        this.pagination.isprev = false;
      }

      this.totalpage = response.meta['totalCount'];
      let self = this.paging.self['page'];
      if ( self *  response.meta['perPage'] < this.totalpage){
        this.pagination.last_index = (self * response.meta['perPage']);
      }else{
        this.pagination.last_index = this.totalpage;
      }
      
      this.pagination.first_index = ((self-1)*response.meta['perPage'])+1;
    });
   
  }

  callsearch(){
    let qs = this.queryString()
    this.load(qs)
  }

  queryString(){
      
      let qs = '&limit='+this.child_search.show+'&keyword='+this.child_search.keyword;
      if ( this.sortColumn != ''){
        qs += '&sort='+this.sortDirection+'&col='+this.sortColumn;
      }
      return qs ;
    }

    nextPage(){
      let qs = this.queryString();
      qs += '&page='+this.pagination.next_page;
      this.load('&'+qs)  
    }

    prevPage(){
      let qs = this.queryString();
      qs += '&page='+this.pagination.previous_page;
      this.load('&'+qs)  
    }  

    sortColumn: string = '';
    sortDirection: string = 'asc'; // 'asc' atau 'desc'
    getSortIcon(column: string) {
      if (this.sortColumn !== column) return 'sort-icon';
      return this.sortDirection === 'asc' ? 'sort-icon asc' : 'sort-icon desc';
    }

    sortTable(column: string) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
      this.callsearch();
    }
}
