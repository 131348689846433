<div class="col-md-12 col-sm-12  ">
    <div class="row" style="display: block;">
      <div class="col-md-12 col-sm-12  ">
        <div class="x_panel">
          <div class="x_title">
            <div class="d-flex align-items-center">
              <div class="title-left" style="margin-right: 15px; padding-top:10px">
                <h3 style="
              font-size: 24px;
              font-weight: bold;
              color: #3A3A3A;
            ">URL Management
            <!-- @if (auth.myPermission('Permission.PR.Create')) {  -->
            <button type="button" routerLink="/urlmanagement/add" class="btn btn-primary btn-sm btn-oldblue" role="button" >
                  <i class="fa fa-plus fa-md"></i>
              </button>  
            <!-- } -->
          </h3>  
  
              </div>
              
            </div>
            <div class="clearfix"></div>
  
          </div>
          
          <div class="x_content">
            <div class="row">
              <div class="col-sm-6">
                <div class="dataTables_length" id="datatable_length">
                  <label>Show 
                    <select name="datatable_length" aria-controls="datatable" class="form-control input-sm" [(ngModel)]="child_search.show" [ngModelOptions]="{standalone: true}" (change)="callsearch()">
                      <option value="{{opt}}" *ngFor="let opt of show_options; let i = index;">{{opt}}</option>
                      </select> entries</label>
                </div>
              </div>
              <div class="col-sm-6">
                <div id="datatable_filter" class="dataTables_filter">
                  <label>Search:<input type="search" class="form-control input-sm" placeholder="" aria-controls="datatable" [(ngModel)]="child_search.keyword" [ngModelOptions]="{standalone: true}" (keyup)="callsearch()"></label>
                </div>
              </div>
            </div>

            <table class="table custom-table dataTable table-striped table-bordered" style="width:100%">
              <thead>
                <tr>
                  <th>#</th>
                  <th  (click)="sortTable('title')" [ngClass]="getSortIcon('title')">Parent Title</th>
                  <th  (click)="sortTable('title')" [ngClass]="getSortIcon('title')">Route Title</th>
                  <th  (click)="sortTable('level')" [ngClass]="getSortIcon('level')">Level</th>
                  <th  (click)="sortTable('icon')" [ngClass]="getSortIcon('icon')">Icon</th>
                  <th  (click)="sortTable('link')" [ngClass]="getSortIcon('link')">Link</th>
                  <th  (click)="sortTable('sequence')" [ngClass]="getSortIcon('sequence')">Sequence</th>
                  <!-- <th>Created At</th>
                  <th>Updated At</th> -->
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data; let i = index;">
                  <td scope="row">{{(pagination.first_index +i)}}</td>
                  <td>{{item.parent_name}}</td>
                  <td>{{item.title}}</td>
                  <td>{{item.level}}</td>
                  <td><i [ngClass]="item.icon"></i> {{item.icon}}</td>
                  <td>{{item.link}}</td>
                  <td>{{item.sequence}}</td>
                  <td class="tdrow">
                    <a style="padding:0px 5px;" [routerLink]="['/urlmanagement/update',item.id]"><i class="fa fa-edit inrow"></i></a> 
                    <!-- <a style="padding:0px 5px;" [routerLink]="['/urlmanagement/detail',item.name]"><i class="fa fa-info-circle inrow"></i></a> -->
                  </td>
                </tr>
                
              </tbody>
            </table>
            <!-- start pagination -->
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-5">
                    <div class="dataTables_info" id="datatable-checkbox_info" role="status" aria-live="polite">Showing {{pagination.first_index}} to {{pagination.last_index}} of {{pagination.totalrecords}} entries</div>
                  </div>
                  <div class="col-sm-7">
                    <div class="dataTables_paginate paging_simple_numbers" id="datatable-checkbox_paginate">
                      <ul class="pagination">
                        <li class="paginate_button previous disabled" id="datatable-checkbox_previous">
                          <a *ngIf="pagination.isprev" aria-controls="datatable-checkbox" data-dt-idx="0" tabindex="0" (click)="prevPage()"  style="cursor: pointer;">Previous</a>
                          <!-- <a aria-controls="datatable-checkbox" data-dt-idx="0" tabindex="0">Previous</a> -->
                        </li>
                        
                        <li class="paginate_button ">
                          <a  aria-controls="datatable-checkbox" data-dt-idx="5" tabindex="1">{{pagination.current}}</a>
                        </li>
                        <li class="paginate_button next" id="datatable-checkbox_next">
                          <a  *ngIf="pagination.isnext" aria-controls="datatable-checkbox" data-dt-idx="7" tabindex="2" (click)="nextPage()" style="cursor: pointer;">Next</a>
                          <!-- <a  *ngIf="!pagination.isnext" aria-controls="datatable-checkbox" data-dt-idx="7" tabindex="2" >Next</a> -->
                        </li>
                      </ul></div></div></div>

              </div>
            </div>

          </div>
          
        </div>
      </div>

      <div class="clearfix"></div>
      
    
    </div>
  </div>