import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageComponent } from './layouts/sample/page/page.component';
import { PrivateComponent } from './layouts/private/private/private.component';
import { SigninComponent } from './pages/public/signin/signin.component';
import { authGuard } from './auth.guard';
import { Error401Component } from './layouts/error401/error401.component';
import { Error404Component } from './layouts/error404/error404.component';

const routes: Routes = [
  //Layout publicpage
  {
    path:'signin',
    //redirectTo : 'dashboard',
    component: SigninComponent
   
  },
  // {
  //   path:'signout',
  //   //redirectTo : 'dashboard',
  //   //component: SignComponent
   
  // },

  {
      path:'',
      redirectTo : 'dashboard',
      pathMatch: 'full'
     
    },

  //Layout Auth
  {
    path: 'sample',
    component: PageComponent
  },
  {
    path: 'error401',
    component: Error401Component
  },
  {
    path: 'error404',
    component: Error404Component
  },
  {
    path: '',
    component: PrivateComponent,
    canActivate:[authGuard],
    children: [{
      path: 'dashboard',
      loadChildren: () => import('./pages/private/dashboard/dashboard.module').then(m=>m.DashboardModule)
    },
    {
      path: 'erf',
      loadChildren: () => import('./pages/private/erf/erf.module').then(m=>m.ErfModule)
    },
    {
      path: 'cqr',
      loadChildren: () => import('./pages/private/cqr/cqr.module').then(m=>m.CqrModule)
    },
    {
      path: 'po',
      loadChildren: () => import('./pages/private/po/po.module').then(m=>m.PoModule)
    },
    {
      path: 'pr',
      loadChildren: () => import('./pages/private/pr/pr.module').then(m=>m.PrModule)
    },
    {
      path: 'goodreceive',
      loadChildren: () => import('./pages/private/goodreceive/goodreceive.module').then(m=>m.GoodreceiveModule)
    },
    {
      path: 'goodreturn',
      loadChildren: () => import('./pages/private/goodreturn/goodreturn.module').then(m=>m.GoodreturnModule)
    },
    {
      path: 'master',
      //component: PrivateComponent,
      children: [
        {
          path: 'vendortype',
          loadChildren: () => import('./pages/private/vendortype/vendortype.module').then(m=>m.VendortypeModule)
        },
        {
        path: 'vendor',
        loadChildren: () => import('./pages/private/vendor/vendor.module').then(m=>m.VendorModule)
      }]
    },
    {
      path: 'rolepage',
      loadChildren: () => import('./pages/private/roletemplate/roletemplate.module').then(m=>m.RoletemplateModule)
    },
    {
      path: 'report',
      loadChildren: () => import('./pages/private/report/report.module').then(m=>m.ReportModule)
    },
    {
      path: 'urlmanagement',
      loadChildren: () => import('./pages/private/urlmanagement/urlmanagement.module').then(m=>m.UrlmanagementModule)
    }
  ]
  },
  

  { path: '**', redirectTo: 'error404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
