import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './services/auth.service';
import { inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { MenuService } from './services/menu.service';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root' // Pastikan ini ada
})
export class authGuard implements CanActivate {
  constructor(private router: Router, private menuService: MenuService, private authService: AuthService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):  Promise<boolean> {
    
    const tokenService = this.authService.getToken(); 
    const tokenInternalService = this.authService.getIntenalToken(); 

    if (!tokenService || !tokenInternalService) {
      this.router.navigate(['/signin']);
      return false;
    }

    try {
      // Pastikan menu sudah dimuat sebelum melakukan pengecekan
      const menuItems = await this.menuService.getMenu({});
      const currentUrl = state.url;

      // Debug: Cek isi menuItems dan currentUrl
      console.log("🔹 Menu Items:", menuItems);
      console.log("🔹 Current URL:", currentUrl);

      const found = this.menuService.checkMenuAccess(menuItems, currentUrl);

      if (!found) {
        console.warn("⛔ Akses ditolak untuk:", currentUrl);
        this.router.navigate(['/error401']);
        return false;
      }

      return true;
    } catch (error) {
      console.error("❌ Error saat mengambil menu:", error);
      this.router.navigate(['/error401']);
      return false;
    }

  }
}

/*
export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService)
  const tokenservice = authService.getToken(); 
  const tokenInternalservice = authService.getIntenalToken(); 
  const router = inject(Router);
  //console.log('token',tokenservice)
  //console.log('tokenInternal',tokenInternalservice)
  
  if ( tokenservice && tokenInternalservice) {
    return true;
  } else {
    // Redirect to the login page if the user is not authenticated
    router.navigate(['/signin']);
    return false;
  }

  return true;
};
*/

