import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error401',
  // standalone: true,
  // imports: [],
  templateUrl: './error401.component.html',
  styleUrl: './error401.component.css'
})
export class Error401Component {
  constructor(private router: Router) {}
  goToDashboard() {
    this.router.navigate(['/dashboard']); // Menggunakan absolute path
  }

}
